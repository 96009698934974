<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="660" :centered="true">
    <template slot="footer">
      <a-button v-if="showType=='add'||showType=='edit'" @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <a-form-model ref="modalForm" :model="formDatas" :rules="formRules" :label-col="{span:7}" :wrapper-col="{span:14}">
      <a-form-model-item label="报警命令" prop="itemname">
        <a-input v-model.trim="formDatas.itemname" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="报警代码" prop="itemcode">
        <a-input v-model.trim="formDatas.itemcode" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="回路号/信息类型" prop="itemloop">
        <a-input v-model.trim="formDatas.itemloop" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="部位号" prop="itempart">
        <a-input v-model.trim="formDatas.itempart" />
      </a-form-model-item>
      <a-form-model-item label="部件类型" prop="itemparttype">
        <a-input v-model.trim="formDatas.itemparttype" />
      </a-form-model-item>
      <a-form-model-item label="厂家" prop="vender">
        <a-input v-model.trim="formDatas.vender" />
      </a-form-model-item>
      <a-form-model-item label="型号" prop="model">
        <a-input v-model.trim="formDatas.model" />
      </a-form-model-item>
      <a-form-model-item label="是否进入系统" prop="istrue">
        <a-select v-model="formDatas.istrue">
          <a-select-option :value="0">否</a-select-option>
          <a-select-option :value="1">是</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import {addItemList, modifyItemList} from "A/wlw";

export default {
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
  },
  data() {
    return {
      modalVisible: false,
      formDatas: {
        itemlistid:'',
        itemname: '',
        itemcode: '',
        itemloop: '',
        itempart: '',
        itemparttype: '',
        vender: '',
        model: '',
        istrue: '',
      },
      formRules: {
        itemname: [{required: true, message: '请输入报警条目名称'}],
        itemcode: [{required: true, message: '请输入报警条目代码'}],
        vender: [{required: true, message: '请输入厂家'}],
        model: [{required: true, message: '请输入型号'}],
        istrue: [{required: true, message: '请选择是否加入系统'}],
      }
    }
  },
  computed: {
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    initDetail() {
      if(this.detailData && (this.showType == 'edit')) {
        this.$nextTick(() => {
          for(let key in this.formDatas) {
            this.formDatas[key] = this.detailData[key];
          }
        })
      }
    },
    resetDatas() {
      this.$refs.modalForm.resetFields();
    },
    confirm() {
      if(this.showType == 'add' || this.showType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if(valid) {
            this.showLoading();
            let params = {
              ...this.formDatas,
            };
            if(this.showType == 'add') {
              addItemList(params).then(res => {
                this.hideLoading();
                if(res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                }else {
                  this.$message.error(res.errormsg||'操作失败');
                }
              })
            }else {
              modifyItemList(params).then(res => {
                this.hideLoading();
                if(res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                }else {
                  this.$message.error(res.errormsg||'操作失败');
                }
              })
            }
          }else {
            return false;
          }
        })
      }else {
        this.modalVisible = false;
      }
    }
  }
}
</script>