<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
             <a-form-model-item label="报警命令" prop="itemname">
              <a-input v-model.trim="queryParams.itemname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="报警代码" prop="itemcode">
              <a-input v-model.trim="queryParams.itemcode" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="是否进入系统" prop="model">
              <a-select v-model="queryParams.istrue">
                <a-select-option :value="0">否</a-select-option>
                <a-select-option :value="1">是</a-select-option>
              </a-select>
            </a-form-model-item>
<!--            <a-form-model-item label="厂家" prop="vender">-->
<!--              <a-input v-model.trim="queryParams.vender" placeholder=""></a-input>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item label="型号" prop="model">-->
<!--              <a-input v-model.trim="queryParams.model" placeholder=""></a-input>-->
<!--            </a-form-model-item>-->
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">代码条目</h3>
          <div class="table-btns">
            <a-button type="primary" @click="add"><a-icon type="plus"></a-icon>新建</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="itemlistid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="istrue" slot-scope="value, record">
            <span>{{value==0?'否':'是'}}</span>
          </span>
          <span slot="operation" slot-scope="value, record, index">
            <a-button type="link" size="small" @click="modify(value, record, index)">修改</a-button>
            <a-button type="link" size="small" @click="deleteConfirm(value, record, index)">删除</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
  </page-container>
</template>

<script>
import { getItemFromArrayByKey } from 'U'
import store from '@/store'
import pagination from '@/mixins/pagination'
import AddOrEditModal from './AddOrEditModal'
import {deleteItemList, getItemListListByCondition} from "A/wlw";
export default {
  name: 'fireItemList',
  mixins: [pagination],
  components: {
    AddOrEditModal
  },
  data() {
    return {
      showAdvanced: false,
      queryParams: {
        itemname: '',
        itemcode: '',
        vender: '',
        model:'',
        istrue:'',
      },
      tableColumns: [
        {
          title: '报警命令',
          dataIndex: 'itemname',
          key: 'itemname',
          ellipsis: true,
        },
        {
          title: '报警代码',
          dataIndex: 'itemcode',
          key: 'itemcode',
          ellipsis: true,
        },
        {
          title: '回路号/信息类型',
          dataIndex: 'itemloop',
          key: 'itemloop',
          ellipsis: true,
        },
        {
          title: '部件类型',
          dataIndex: 'itemparttype',
          key: 'itemparttype',
          ellipsis: true,
        },
        {
          title: '部位状态',
          dataIndex: 'itempartstatus',
          key: 'itempartstatus',
          ellipsis: true,
        },
        {
          title: '厂家',
          dataIndex: 'vender',
          key: 'vender',
          ellipsis: true,
        },
        {
          title: '型号',
          dataIndex: 'model',
          key: 'model',
          ellipsis: true,
        },
        {
          title: '是否进入系统',
          dataIndex: 'istrue',
          key: 'istrue',
          ellipsis: true,
          scopedSlots: { customRender: 'istrue' }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalShowType: 'add',
      modalVisible: false,
      modalDetailData: null,
    }
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    if(!toTab) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  computed:{
    pageTitle() {
      return "消防报警条目"
    },
    breadcrumb() {
      const pages = [{name:"Ulake Lab",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTableData();
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.queryParams.istrue=null
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getItemListListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.itemlistid);
      }).catch(()=>{
      });
    },
    delete(itemlistid) {
      this.showLoading();
      if(itemlistid) {
        let params = {
          itemlistid
        };
        deleteItemList(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    add() {
      this.modalShowType = 'add';
      this.modalDetailData = null;
      this.modalVisible = true;
    },
    modify(value, record) {
      this.modalShowType = 'edit';
      this.modalDetailData = record;
      this.modalVisible = true;
    },
  }
}
</script>

<style lang="scss" scoped>
</style>